import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../../service";
import { addToCart, deleteItemShoppingCard } from "./cartSlice";


const user_id = localStorage.getItem("user_id");
const studio_id = localStorage.getItem("studio_id");
const client_id = localStorage.getItem("client_id");
const event_id = localStorage.getItem("event_id");
const current_user_id = localStorage.getItem("current_user_id");
const user_type = localStorage.getItem('user_type')
const event_name = localStorage.getItem('eventName')
const owner_id =  localStorage.getItem('studio_mode') === 'true' ? localStorage.getItem('studio_id') : localStorage.getItem('client_id')
const AddCartImage = createAsyncThunk(
    "add/cart/image",
    async(cart,{dispatch})=>{
       
      const response = await request.post("shopping_cart_store", {
        shopping_cart:cart,
        user_id,
        user_type,
        current_user_id,
        event_id,
        client_id,
        studio_id,
         owner_id,
        event_name
     
      });

      
    }
  )


  const GetCartImages = createAsyncThunk(
    "get/cart/image",
    async ( _ , { dispatch, rejectWithValue }) => {
      try {
        const response = await request.post("shopping_cart_get", {
          user_id,
          user_type,
          current_user_id,
          event_id,
          client_id,
          studio_id,
          owner_id,
          status: 0
        });
        
        dispatch(addToCart(response.data.data))
      
        return response.data; 
        
      } catch (error) {
        console.log("Error fetching cart images:", error);
      
        return rejectWithValue(error.response?.data || "Failed to fetch cart images");
      }
    }
  );


  const DeleteImageCart = createAsyncThunk(
    "delete/image/cart",
    async ({ key,card_id }, { rejectWithValue,dispatch }) => {
      try {
        const response = await request.post("delete_cart_data", {
          key, 
          id: card_id,
             
          user_id
        });

        dispatch(deleteItemShoppingCard(key))
        return response.data
      } catch (error) {
     
        return rejectWithValue(error.response?.data || "Failed to fetch cart images");
      }
    }
  );

  const DeleteAllImageCart = createAsyncThunk(
    "delete/all/image/cart",
    async ({cart_id}, { rejectWithValue }) => {
      try {
        const response = await request.post("clear_cart_data", {

          cart_id,
          user_id,
          clear_all:1
        });

        return response.data
      } catch (error) {
       
        return rejectWithValue(error.response?.data || "Failed to fetch cart images");
      }
    }
  );


  const EditImageCart = createAsyncThunk(
    "edit/image/cart",
    async({id,key,updatedItem},{rejectWithValue})=>{
      try {
        const response = await request.post("update_cart_data", {
          user_id,
          id,key,updatedItem
        });
      } catch (error) {
       
        return rejectWithValue(error.response?.data || "Failed to fetch cart images");
      }
    }
  )
  
  

  export {
    AddCartImage,
    GetCartImages,
    DeleteImageCart,
    DeleteAllImageCart,
    EditImageCart
  }