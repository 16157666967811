import React, { useContext, useEffect, useState } from 'react';
import './ShoppingCart.css';
import { Col, Modal, Row } from 'antd';
import MainImage from '../Clients/Prints/mainImage/MainImage';

import AddButton from "../Clients/AddButton/AddButton";
import { useDispatch } from 'react-redux';


const EditorCart = ({ selectedItemIndex, setSelectedItemIndex,isEditorCartOpen,data,updateCart }) => {
  
 


  const [formData, setFormData] = useState({
    image: '',
    selectedBg: '',
    quantity: 0,
    currentSize: {},
    imageSize: { width: 0, height: 0 },
    switcher: false,
    processedData: null,
    textValues: '',
  });

  const dispatch = useDispatch()



 
  
  useEffect(() => {
    if (selectedItemIndex !== null && data[selectedItemIndex]) {
      setFormData((prev) => ({
        ...prev,
        ...data[selectedItemIndex],
        switcher: prev.switcher, 
      }));
    }
  }, [selectedItemIndex, data]);
  
  

  const handleModalClose = () => {
    setSelectedItemIndex(null);
  };


  const updateCartData = () => {
    if(updateCart){
      dispatch(updateCart({ index: selectedItemIndex, updatedData: formData }));
    }
    // if(calculateTotals){
    //   dispatch(calculateTotals())
    // }

   ;
    handleModalClose();
  };

 



  return (
      <Modal
          title="Edit Shopping Cart"
          open={selectedItemIndex !== null}
          onCancel={handleModalClose}
          footer={null}
          className="modalEdit"
      >
        <Row>
          <Col span={24}>
            <MainImage
                printImage={formData.image}
                currentSize={formData.currentSize}
                setProcessedData={(data) => setFormData({ ...formData, processedData: data })}
                processedData={formData.processedData}
                isSwitchChecked={formData.switcher}
                setIsSwitchChecked={(checked) => setFormData({ ...formData, switcher: checked })}
                imageSize={formData.imageSize}
                setImageSize={(size) => setFormData({ ...formData, imageSize: size })}
                selectedBg={formData.selectedBg}
                newEditor={false}
              
               
            />
          </Col>
          <Col span={24}>
            <AddButton
              quantity={formData.quantity}
              updateCartData={updateCartData}
              printImage={formData.image}
              currentSize={formData.currentSize}
              processedData={formData.processedDataI}
              isEditorCartOpen={isEditorCartOpen}
              newEditor={false}
            />
          </Col>
        </Row>
      </Modal>
  );
};

export default EditorCart;