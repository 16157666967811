import { useSelector } from "react-redux";

const useCartData = ()=>useSelector((state)=>state.cart.cartData)
const useTotalQuantity = () => useSelector((state) => state.cart.totalQuantity);
const useQuantity = () => useSelector((state) => state.cart.quantity);
const useTextValues = () => useSelector((state) => state.cart.textValues);
const useProcessedData = () => useSelector((state) => state.cart.processedDataI);
const useIsSwitchChecked = () => useSelector((state) => state.cart.isSwitchCheckedI);
const useTotalPrice = ()=>useSelector((state)=>state.cart.totalPrice)
const useCurrentCart = ()=>useSelector((state)=>state.cart.currentCart)

const useFolderData = () => useSelector((state)=>state.cart.folderData)

const useCartCurrentPrice = () =>useSelector((state)=>state.cart.cartCurrentPrice)

const useCardId = ()=>useSelector((state)=>state.cart.cardId)

const useTotalPriceQuantity = () =>useSelector((state)=>state.cart.totalPriceQuantity)

export {

    useCartData,
    useTotalQuantity,
    useQuantity,
    useTextValues,
    useProcessedData,
    useIsSwitchChecked,
    useTotalPrice,
    useCurrentCart,
    useFolderData,
    useCartCurrentPrice,
    useCardId,
    useTotalPriceQuantity
}