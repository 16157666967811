import { useSelector } from "react-redux";

const useImages = () => useSelector((state) => state.gallery.images)
const useTotalImages = () => useSelector((state) => state.gallery.totalImages)
const useIsGalleryLoading = () => useSelector((state) => state.gallery.isGalleryLoading)
const useLightboxIsOpen = () => useSelector((state) => state.gallery.lightboxIsOpen)
const useIsLightboxLoading = () => useSelector((state) => state.gallery.isLightboxLoading)
const useFavoriteImages = () => useSelector((state) => state.gallery.favoriteImages)
const usePrivateImages = () => useSelector((state) => state.gallery.privateImages)
const useSelectedImages = () =>
    useSelector((state) => state.gallery.selectedImages);

const useIsSelectedAll = () =>
    useSelector((state) => state.gallery.isSelectedAll);

const useShopingCard = () => useSelector((state) => state.gallery.shopingCard)

const useFolderDataList = () => useSelector((state) => state.gallery.folderDataList)

const useSelectedFolder = () =>useSelector((state)=>state.gallery.selectedFolder)


const useSelectAllImagesDownload = ()=>useSelector((state)=>state.gallery.selectAllImagesDownload)

const useDownloadImages = ()=>useSelector((state)=>state.gallery.downloadImages)

const useSearchImages = () => useSelector((state)=>state.gallery.searchImages)

export {
    useImages,
    useTotalImages,
    useIsGalleryLoading,
    useLightboxIsOpen,
    useIsLightboxLoading,
    useFavoriteImages,
    usePrivateImages,
    useSelectedImages,
    useIsSelectedAll,
    useShopingCard,
    useFolderDataList,
    useSelectedFolder,
    useSelectAllImagesDownload,
    useDownloadImages,
    useSearchImages
}