import React, {useState, useRef, useEffect} from 'react';
import {Button, Modal, Steps, theme} from 'antd';
import Checkout from "../../Checkout";
import ShippingAddress from "../../ShippingAddress";
import Done from "../../Done";
import OrderDetails from "../../OrderDetails";
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";
import styles from  "./mainCheckout.module.css"
import StripeCheckout from "../../StripeCheckout";
import { useDispatch } from 'react-redux';
import { GetCreditOwner, ShoppingCardStore } from '../store/thunk';
import { useCreditStudio, useOrderDetails } from '../store/selector';
import {setCreditStudio, setOrderDetails} from '../store/mainCheckoutSlice';


const MainCheckout = ({cartData,shoppingCartInfo,totalPrice,setCart,setData}) => {
  const [balance, setBalance] = useState(0.00);
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const client_id = localStorage.getItem('client_id');
  const studio_mode = localStorage.getItem('studio_mode') || '';
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [shippingAddress, setShippingAddress] = useState({});
  const [checkout, setCheckout] = useState({});
  // const [orderDetails, setOrderDetails] = useState({});
  const shippingAddressRef = useRef(null);
  const checkoutRef = useRef(null);
  const orderDetailsRef = useRef(null);
  const doneRef = useRef(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [productDirection, setProductDirection] = useState('');
  const [productName, setProductName] = useState("");



  const dispatch = useDispatch()
  const creditStudio = useCreditStudio()


  const orderDetails = useOrderDetails()


  const orderDataForCreditCard={
    productBalance: balance*100 || '',
    productDirection:productDirection,
    productName:productName,
  }


  const [checkOutOrder, orderLoading, orderError] = useFetching(
    async (data,order_id,url) => {
      const {data: res} = await clientAPI.checkoutOrder(data,order_id);
      if (res) {

        window.location.href = url.url;
        // localStorage.setItem('cart',[]);
        // setCart([])
        // setData([])
      } else {
        orderError()
      }
    }
  );



  const handleSaveData = (data) => {
    if (current === 0) {
      setShippingAddress(data);
    } else if (current === 1) {
      setCheckout({...data, olineUsed: 0});
    } else if (current === 2) {
      dispatch(setOrderDetails(data))
      // setOrderDetails(data);
    } else if (current === 3) {
      dispatch(setOrderDetails(data))
      // setOrderDetails(data);
    }
  };

  

  const showModal = () => {
    dispatch(ShoppingCardStore({shoppingCartData:cartData,shoppingCardInfo:shoppingCartInfo}))
  
    setIsModalOpen(true);
  };

  const handleCancel = () => {  setIsModalOpen(false); };

  const doneSHoppingCart = () => {
    setIsModalOpen(false);
    setCurrent(0)
  };

  const next = () => {
    if (current === 0) {
      shippingAddressRef.current
        .validateAndProceed()
        .then(() => {
          setCurrent(current + 1);
        })
        .catch((error) => {
          console.error('Validation error:', error);
        });
    } else if (current === 1) {
      checkoutRef.current
        .validateAndProceed()
        .then(() => {
          setCurrent(current + 1);
        })
        .catch((error) => {
          console.error('Validation error:', error);
        });
    }else if (current === 2) {
      orderDetailsRef.current
        .validateAndProceed()
        .then(() => {
          setCurrent(current + 1);

        })
        .catch((error) => {
          console.error('Validation error:', error);
        });
    }else if (current === 3) {
      doneRef.current
        .validateAndProceed()
        .then(() => {
          setCurrent(current + 1);
        })
        .catch((error) => {
          console.error('Validation error:', error);
        });
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current > 0) {
      if (current === 1) {
        setShippingAddress(shippingAddress);
      } else if (current === 2) {
        setCheckout(checkout);
      }else if (current === 3) {
        setCheckout(checkout);
      }
      setCurrent(current - 1);
    }
  };

  const handleEdit = () => {
    setCurrent(0);

    if (orderDetailsRef.current && orderDetailsRef.current.scrollIntoView) {
      orderDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const steps = [
    {
      title: 'Shipping address',
      content: <ShippingAddress
        ref={shippingAddressRef}
        shippingAddress={shippingAddress}
        setShippingAddress={setShippingAddress}
      />,
    },
    {
      title: 'Checkout',
      content: <Checkout
        ref={checkoutRef}
        totalPrice={totalPrice}
        balance={balance}
        setBalance={setBalance}
        setShippingAddress={handleSaveData}
        shippingAddress={shippingAddress}
        checkout={checkout}
        setCheckout={setCheckout}
        creditStudio={creditStudio}
      />,
    },{
      title: 'Order Details',
      content: <OrderDetails
        ref={orderDetailsRef}
        totalPrice={totalPrice}
        balance={balance}
        setBalance={setBalance}
        setCheckout={handleSaveData}
        setShippingAddress={handleSaveData}
        checkout={checkout}
        shippingAddress={shippingAddress}
        orderDetails={orderDetails}
        OrderDetails={setOrderDetails}
        shoppingCartInfo={shoppingCartInfo}
        cartData={cartData}
        onEdit={handleEdit}
      />
    },
    {
      title: 'Done',
      content: <Done
        balance={balance}
        totalPrice={totalPrice}
        shoppingCartInfo={shoppingCartInfo}
        checkout={checkout}
        shippingAddress={shippingAddress}
        checkOutOrder={checkOutOrder}
        cartData={cartData}
        orderDataForCreditCard={orderDataForCreditCard}
        setOrderNumber={setOrderNumber}
        order_id={orderNumber}
      />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));


  const getProductDirection = () => {
    const productDetails = cartData.map(product => `${product.productType} - ${product.size} (${product.quantity})`);
    const combinedProductDetails = productDetails.join(" / ");
    const combinedProductName = productDetails[0] + (productDetails.length > 1 ? " ..." : "");
    setProductDirection(combinedProductDetails);
    setProductName(combinedProductName);
  };

  useEffect(() => {

    getProductDirection()
  }, [cartData]);
  useEffect(() => {
    if (current === 0) {
      setShippingAddress(shippingAddress);
    } else if (current === 1) {
      setCheckout(checkout);
    } else if (current === 2) {
      setOrderDetails(orderDetails);
    }else if (current === 3) {
      setOrderDetails(orderDetails);
    }
  }, [current, shippingAddress, checkout, orderDetails]);

  const [getUserCredit, creditLoading, creditError] = useFetching(
    async (shoppingCartInfo) => {
      const {data: res} = await clientAPI.getCreditByOwnerId(shoppingCartInfo.owner_id);
      if (res) {
        dispatch(setCreditStudio(res.data));
      } else {
        creditError()
      }
    }
  );
 
  useEffect(() => {
     dispatch(GetCreditOwner({ownerID:shoppingCartInfo.owner_id}))
     
    //  getUserCredit(shoppingCartInfo)
  }, []);

  return (
    <>
      <Button className="main-checkout-button"  onClick={showModal} >
        Proceed to Checkout
      </Button>
      <Modal className='CheckoutModal' open={isModalOpen} footer={false} onCancel={handleCancel} >
        <Steps current={current} items={items} style={{padding:'20px'}}/>
        <div style={{ margin: 24, position:'absolute'}}  >  {studio_mode === 'true' ? <span className={styles.galleryBtnClientMode}>Studio mode</span> : <span  className={styles.galleryBtnClientMode}>Client mode</span>}</div>
        <div style={contentStyle}>{steps[current].content}</div>
        <div style={{ marginTop: 24}}></div>
        {current > 0  && current !== steps.length - 1 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          current === 2 && balance > 0 ? (
        <StripeCheckout
          shoppingCartInfo={shoppingCartInfo}
          checkout={checkout}
          shippingAddress={shippingAddress}
          checkOutOrder={checkOutOrder}
          cartData={cartData}
          orderDataForCreditCard={orderDataForCreditCard}
          productDirection={productDirection}
          setProductDirection={setProductDirection}
          setProductName={setProductName}
          productName={productName}
          setOrderNumber={setOrderNumber}
          orderNumber={orderNumber}
          balance={balance}
          totalPrice={totalPrice}
        />
          ) : (
            <Button  type="primary" onClick={() => next()}>
             Next
            </Button>
          )
        )}

        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => doneSHoppingCart()}>
            Done
          </Button>
        )}
      </Modal>
    </>
  );
};
export default MainCheckout;