import React, {useEffect, useRef, useState, useContext, useCallback} from 'react';
import {Layout, Col, Row, Tour, message, Alert} from 'antd';
import './CardMain.css';
import '../Prints/selectSize/SelectSize.css'
import {animated, useSpring} from "@react-spring/web";
import CardMainCenter from "../CardMainCenter/CardMainCenter";
import DropZoneContext from '../../../context/DropZoneContext';
import GalleryScrollHorizontal from "../GalleryScrollHorizontal/GalleryScrollHorizontal";
import TemplateVerticalView from "../Gallery/TemplateVerticalView/TemplateVerticalView";
import HeaderClient from '../Header/Header'
import AddButton from "../AddButton/AddButton";
import {CartContext} from "../../../context/CartContext";
import {useWindowSize} from "../../Utils/WindowSize";
import {ClientChat} from "../../HelpChat/Chat";
import {useFetching} from "../../../hoc/fetchingHook";
import clientsAPI from "../../../api/api";
import {AddCartImage, GetCartImages} from "../../Cart/store/thunk";
import {useDispatch} from "react-redux";
import { useCartCurrentPrice } from '../../Cart/store/selector';
import { calculateTotals, updateTotalQuantity } from '../../Cart/store/cartSlice';

const { Header, Content, Sider } = Layout;

function CardMain({isEditorCartOpen, cardObjectEdit,cardObject,setCardObject}) {

    const [helperShow, setHelperShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const { width } = useWindowSize();
    const isWindowWidthGreaterThan577 = width > 577;
    const [cardType, setCardType] = useState('Fold Over');
    const [imageDropZones, setImageDropZones] = useState({});
    const [isDraggingElem, setIsDraggingElem] = useState(false);
    const [currentSide, setCurrentSide] = useState('front');
    const [selectedIcon, setSelectedIcon] = useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const { addToCart} = useContext(CartContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [templateForm, setTemplateForm] = useState({});
    const [studioInfo, setStudioInfo] = useState();
    const client_id =localStorage.getItem('client_id',)
    const [filteredObject, setFilteredObject] = useState({ options: [] });
    const initialCardObject = {
        front: {
            template: [],
            images: [],
            background: [],
            text: [],
            other: []
        },
        back: {
            template: [],
            images: [],
            background: [],
            text: [],
            other: []
        },
        envelope: {
            template: [],
            images: [],
            background: [],
            text: [],
            other: []
        }
    };
     const [cardObjects, setCardObjects] = useState(initialCardObject);



 
    const [open, setOpen] = useState(helperShow);

    const dispatch = useDispatch()
    const price  = useCartCurrentPrice()

    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }

    }, [helperShow]);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const steps = [
        {
            title: 'Card Front Page',
            description: 'Put your files here.',
            target: () => ref1.current,
        },
        {
            title: 'Save',
            description: 'Save your changes.',
            target: () => ref2.current,
        },
        {
            title: 'Other Actions',
            description: 'Click to see other actions.',
            target: () => ref3.current,
        },
        {
            title: 'Other Actions 4',
            description: 'Click to see other actions.',
            target: () => ref4.current,
        },
    ];

    const span18 = isEditorCartOpen ? 24 : 18;

    const onClose = () => {
       setErrorMessage(false)
    };

    const getBackCardTransform = () => {
        if (currentSide === 'preview') {
            return 'translateX(38%) translateY(-100%) scale(0.7) rotate3d(12, 5, 10, -14deg)'; // Move the back card right and slightly down when previewing
        } else {
            return 'translateX(0%) translateY(0%) scale(1) rotate3d(0, 0, 0, 0deg)'; // Reset position when not previewing
        }
    };

    const getPreviewFrontCardTransform = () => {
        if (currentSide === 'preview') {
            return 'translateX(-23%) translateY(-18%) scale(0.7) rotate3d(10, 5, 5, -20deg)';
        } else {
            return 'translateX(0%) translateY(0%) scale(1) rotate3d(0, 0, 0, 0deg)'; // Reset position when not previewing
        }
    };

    const { opacity, transformFront, transformBack,transformFrontP } = useSpring({
        opacity: currentSide === 'front' ? 1 : 0,
        transformFront: `perspective(1000px) rotateY(${currentSide === 'back' ? -180 : 0}deg)`,
        transformBack: getBackCardTransform(),
        transformFrontP: getPreviewFrontCardTransform(),
        config: { mass: 5, tension: 500, friction: 80 },

    });

    const handleViewChange = useCallback((view) => {
        setCurrentSide(view);
    }, []);

    const [getStudioInfo, isGetStudioInfo, studioIndoError] = useFetching(async (id) => {
        const res  = await clientsAPI.getClientInfoByID(id);
        if (res) {
            setStudioInfo(res.data);
        }
    });

 
    

    
  


    const handleAddItem = () => {

        const frontImages = cardObjects.front.images;
        const backImages = cardObjects.back.images;

        const hasUndefinedImage = frontImages.some(image => image === undefined) || backImages.some(image => image === undefined);

        if (hasUndefinedImage) {
            setErrorMessage(!errorMessage);
            return;
        }

        const newItem = [{
            productType: 'Card',
            cardObject: cardObjects,
          
            quantity,
            currentSize: {
                width: templateForm.W,
                W: templateForm.W,
                height: templateForm.H,
                H: templateForm.H,
                price:price,
            },
            templateForm,
            filteredObject
        }];
        dispatch(AddCartImage(newItem)).unwrap().then((res)=>{
                dispatch(GetCartImages()).unwrap().then((res)=>{
                    dispatch(calculateTotals())
                    dispatch(updateTotalQuantity())
                })
        }).catch((err)=>{

        })
            
      




        if (frontImages.length ===0 || backImages.length ===0) {
            setErrorMessage(!errorMessage);
        } else {
            setSelectedIcon(true);
            setIsAddedToCart(true);
            // dispatch(AddCartImage(newItem))
            // addToCart(newItem);
            openMessage();
            localStorage.removeItem('ImagesForOrder');
            setErrorMessage(false);

        }
    };

    const openMessage = useCallback(() => {
        const key = 'updatable';
        message.loading({
            content: 'Adding to cart...',
            style: { marginTop: '20vh' },
            key
        });

        setTimeout(() => {
            message.success({ content: 'Added to cart!', key, duration: 2, style: { marginTop: '20vh' } });
            setIsAddedToCart(false);
            setQuantity(1);
            // setCardObject(initialCardObject);

        }, 1000);
    }, []);

    useEffect(() => {
        const getTemplate = () => {
            fetch('https://dev.studioseye.com/api/test2')
                .then((res) => res.json())
                .then((body) => {

                });
        };
        getTemplate();
    }, []);

    useEffect(() => {
        getStudioInfo(client_id)
    },[client_id])

    useEffect(() => {
        if (isEditorCartOpen &&  cardObjectEdit) {
            setCardObject(cardObjectEdit);
        }

    }, [isEditorCartOpen, cardObjectEdit]);

    return (
        <>
            {!isEditorCartOpen &&<HeaderClient  studioInfo={studioInfo?.studioInfo} helperShow={helperShow}  setHelperShow={setHelperShow}/>}

        <DropZoneContext.Provider value={{
            imageDropZones,
            setImageDropZones,
            isDraggingElem,
            setIsDraggingElem
        }}>

            <Row justify='space-between'>
                <Col  span={24} xl={span18}>
                    <Layout >
                        <Content>
                            <Row>
                                {!isEditorCartOpen && <Col xl={6} span={24} className="scroll-main-panel">
                                    <Header className='divNone'></Header>
                                    <GalleryScrollHorizontal/>
                                </Col>
                                }
                                <Col  xl={span18} span={24}>
                                    <Header>
                                        <div className="card-header-content">
                                            {cardType === 'Flat Cards' && (
                                              <Row>
                                                  <Col
                                                    ref={ref1}
                                                    span={6}
                                                    className={`front-back-buttons ${currentSide === 'front' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('front')}
                                                  >
                                                      FRONT
                                                  </Col>
                                                  <Col
                                                    ref={ref2}
                                                    span={6}
                                                    className={`front-back-buttons ${currentSide === 'back' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('back')}
                                                  >
                                                      BACK
                                                  </Col>
                                                  <Col
                                                    ref={ref3}
                                                    span={6}
                                                    className={`front-back-buttons ${currentSide === 'preview' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('preview')}
                                                  >
                                                      PREVIEW
                                                  </Col>
                                                  <Col
                                                    ref={ref4}
                                                    span={6}
                                                    className={`front-back-buttons ${currentSide === 'envelope' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('envelope')}
                                                  >
                                                      ENVELOPE
                                                  </Col>
                                              </Row>
                                            )}

                                            {cardType === 'Fold Over' && (
                                              <Row>
                                                  <Col
                                                    ref={ref1}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'front' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('front')}
                                                  >
                                                      FRONT AND BACK
                                                  </Col>
                                                  <Col
                                                    ref={ref2}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'back' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('back')}
                                                  >
                                                      INSIDE
                                                  </Col>
                                                  <Col
                                                    ref={ref3}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'preview' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('preview')}
                                                  >
                                                      PREVIEW
                                                  </Col>
                                              </Row>
                                            )}
                                            {cardType === 'Photo Cards' && (
                                              <Row>
                                                  <Col
                                                    ref={ref1}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'front' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('front')}
                                                  >
                                                      FRONT
                                                  </Col>
                                                  <Col
                                                    ref={ref3}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'preview' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('preview')}
                                                  >
                                                      PREVIEW
                                                  </Col>
                                              </Row>
                                            )}
                                            {cardType === 'Units' && (
                                              <Row>
                                                  <Col
                                                    ref={ref1}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'front' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('front')}
                                                  >
                                                      FRONT
                                                  </Col>
                                                  <Col
                                                    ref={ref3}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'preview' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('preview')}
                                                  >
                                                      PREVIEW
                                                  </Col>
                                              </Row>
                                            )}
                                            {cardType === 'Print' && (
                                              <Row>
                                                  <Col
                                                    ref={ref1}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'front' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('front')}
                                                  >
                                                      FRONT
                                                  </Col>
                                                  <Col
                                                    ref={ref3}
                                                    span={8}
                                                    className={`front-back-buttons ${currentSide === 'preview' ? 'active' : ''}`}
                                                    onClick={() => handleViewChange('preview')}
                                                  >
                                                      PREVIEW
                                                  </Col>
                                              </Row>
                                            )}
                                        </div>
                                    </Header>
                                    <div className="card-content ">
                                        <div className="card-main">
                                            { (currentSide === 'front' || currentSide === 'back')  && (
                                              <>
                                                  <animated.div
                                                    className="card-div"
                                                    style={{
                                                        height: '100%',
                                                        opacity: opacity.to(o => 1 - o),
                                                        transform: transformFront.to(t => `${t} rotateY(180deg)`),
                                                        zIndex: currentSide === 'back' ? 2 : 1, // Ensure the back card is on top when view is 'back'
                                                    }}
                                                  >
                                                      <CardMainCenter
                                                        currentSide={currentSide}
                                                        cardObject = {cardObject ? cardObject : cardObjects}
                                                        setCardObject = {setCardObject ? setCardObject : setCardObjects}
                                                      />
                                                  </animated.div>
                                                  <animated.div
                                                    className="card-div"
                                                    style={{
                                                        opacity,
                                                        transform: transformFront.to(t => `${t}`),
                                                        zIndex: currentSide === 'front' ? 2 : 1, // Ensure the front card is on top when view is 'front'
                                                    }}
                                                  >
                                                      <CardMainCenter
                                                        currentSide={currentSide}
                                                        cardObject = {cardObject ? cardObject : cardObjects}
                                                        setCardObject = {setCardObject ? setCardObject : setCardObjects}
                                                      />
                                                  </animated.div>
                                              </>
                                            )}
                                            {currentSide === 'preview' && (
                                              <div className="transform_preview">
                                                  <animated.div
                                                    className=""
                                                    style={{
                                                        opacity: opacity.to(o => 1 - o),
                                                        transform: transformFrontP,
                                                        zIndex:3
                                                    }}
                                                  >
                                                      <CardMainCenter
                                                        currentSide="front"
                                                        cardObject = {cardObject ? cardObject : cardObjects}
                                                        setCardObject = {setCardObject ? setCardObject : setCardObjects}
                                                      />
                                                  </animated.div>
                                                  <animated.div
                                                    className=""
                                                    style={{
                                                        transform: transformBack,
                                                        zIndex:2
                                                    }}
                                                  >
                                                      <CardMainCenter
                                                        currentSide = "back"
                                                        cardObject = {cardObject ? cardObject : cardObjects}
                                                        setCardObject = {setCardObject ? setCardObject : setCardObjects}
                                                      />

                                                  </animated.div>
                                              </div>
                                            )}
                                            {currentSide === 'envelope' && (
                                              <>
                                                  <animated.div
                                                    className="card-div"
                                                    style={{
                                                        transform: transformBack.to(t => `${t} translateY(-20%) rotateY(180deg) rotateZ(90deg)`),
                                                        zIndex:2
                                                    }}
                                                  >
                                                      <div className="envelope"></div>
                                                  </animated.div>
                                              </>
                                            )}

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Col>
                {!isEditorCartOpen &&
                  <Col span={24} xl={6}>
                    <Sider width={'100%'} className="CardMainSider"> {contextHolder}
                        <h3>{`Settings for ${currentSide}`}</h3>
                        <Row className="CardMainRow">
                            {errorMessage && (
                              <Alert
                                message="Error"
                                description='Please select images for both front and back'
                                type="error"
                                showIcon
                                closable
                                onClose={onClose}
                              />
                            )}
                                   <span className='addButton922'>
                                       <AddButton
                                         setQuantity={setQuantity}
                                         quantity={quantity}
                                         // isEditorCartOpen={isEditorCartOpen}
                                         selectedIcon={selectedIcon}
                                         isAddedToCart={isAddedToCart}
                                         // updateCartData={updateCartData}
                                         handleAddItem={handleAddItem}
                                         min={templateForm.from}
                                         max={templateForm.to}
                                       />
                                   </span>

                                <TemplateVerticalView
                                  quantity={quantity}
                                  setCurrentSide={setCurrentSide}
                                  currentSide={currentSide}
                                  cardObject = {cardObject ? cardObject : cardObjects}
                                  setCardObject = {setCardObject ? setCardObject : setCardObjects}
                                  setTemplateForm={setTemplateForm}
                                  setCardType={setCardType}
                                  cardType={cardType}
                                  filteredObject={filteredObject}
                                  setFilteredObject={setFilteredObject}
                                />
                        </Row>
                    </Sider>
                </Col>
                }
            </Row>
            {studioInfo?.client_info && (
              <ClientChat clinetInfo={studioInfo?.client_info} clientId={client_id}/>
            )}
            {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}

        </DropZoneContext.Provider>
        </>
    );
}

export default CardMain;
