import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  cartData: [],
  currentCart:{},
  totalQuantity: 0,
  quantity: 1,
  textValues: '',
  processedDataI: null,
  isSwitchCheckedI: 0,
  totalPrice: 0,
  folderData:[],
  cartCurrentPrice:0,
  cardId:0,
  totalPriceQuantity:0
};

const IMG_SERVER = 'https://studioseye.com';
const H_THUMBNAILS = '/H_THUMBNAILS/';

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
   
    addToCart(state, action) {
     

     
       state.cartData = action.payload
    },
    updateCart:(state, action) => {
      const { index, updatedData } = action.payload;
    
      state.cartData[index] = { ...state.cartData[index], ...updatedData };
    },
    updateFolderCart:(state,action)=>{
     
      const { index, updatedData } = action.payload;


      state.folderData[index] = { ...state.folderData[index], ...updatedData };
    },

    checkIsShopingCardLastElement: (state) => {
    
      if (state.cartData.length === 0) {
     
        state.cartData = [];
      }
    },

    deleteItemShoppingCard:(state,action)=>{
      const deletedDataKey = action.payload
    
      
     

     const updateData = state.cartData.filter(elem=>elem.key !== deletedDataKey)

     

     state.cartData = updateData

      
    },
    
    setTotalPrice:(state,action)=>{
        state.totalPrice = action.payload
    },
    setTotalPriceQuantity: (state,action) => {
      state.totalPriceQuantity = state.cartData.reduce((total, item) => {
          return total + parseFloat(item.totalPrice );
      }, 0);
  },
  
 
   updateTotalQuantity: (state) => {
    const shoppingCartData = state.cartData.flatMap(item => item.shopping_cart || []);

    if (shoppingCartData.length > 0) {
        state.totalQuantity = shoppingCartData.reduce((total, item) => {
            const quantity = Number(item.quantity) || 0; 
            return total + quantity;
        }, 0);
    } else {
        state.totalQuantity = state.cartData.reduce((total, item) => {
            const quantity = Number(item.quantity) || 0; 
            return total + quantity;
        }, 0);
    }
},

    setTotalQuantity: (state, action) => {
      
      state.totalQuantity = action.payload;
    },
    updateTextValues:(state, action) => {
      state.textValues = action.payload;
    },
    setProcessedData:(state, action) => {
      state.processedDataI = action.payload;
    },
    toggleSwitch:(state, action) => {
      state.isSwitchCheckedI = action.payload;
    },
    clearAll:(state,action)=>{
      state.cartData = []
    },


    calculateTotals:(state)  =>{
    
      state.cardId = state.cartData[0]?.id
        
      const shoppingCartData = state.cartData.flatMap(item => item.shopping_cart)
      
    


      const newData = shoppingCartData.map((item, index) => {
      
        let itemPrice = 0;
        if (item?.price) {
          itemPrice = parseFloat(item.price);
        } else if (item?.currentSize?.price) {
          itemPrice = parseFloat(item.currentSize.price);
        } else if (item?.templateForm?.price) {
          itemPrice = parseFloat(item.templateForm.price);
        }
      
        let price = itemPrice;
   
        if (
          typeof item?.productType === 'string' &&
          item.productType.includes('Card') &&
          item?.cardObject &&
          item?.filteredObject?.options?.length > 0
        ) {
          const priceOption = item.filteredObject.options.find(
            option => item.quantity >= option.from && item.quantity <= option.to
          );
          if (priceOption) {
            price = parseFloat(priceOption.price);
          }
        }
      
        return {
    
          num: index + 1,
          key: item.key,
          image: item?.image || item?.printImage || '',
          original_image: item?.original_image || '',
          processedData: item?.processedData || '',
          selectedBg: item?.selectedBg || '',
          quantity: item?.quantity || 0,
          productType: item?.productType || '',
          size: `${item?.currentSize?.width || ''} x ${item?.currentSize?.height || ''}`,
          currentSize: {
            width: `${item?.currentSize?.width || ''}`,
            height: `${item?.currentSize?.height || ''}`,
          },
          price: price.toFixed(2),
          totalPrice: (price * (item?.quantity || 0)).toFixed(2),
          textValues: item?.textValues || '',
          retouch: item?.retouch || '',
          switcher: item?.switcher || '',
          selectCardType: item?.templateForm?.product_type || '',
          verticalHorizontal: item?.templateForm?.template_pos || '',
          cardObject: item?.cardObject || null,
          filteredObject: item?.filteredObject || null,
        };
      });
      
   
      
      // const newData = shoppingCartData.map((item, index) => {


      //     let itemPrice;
      //     if (item && item.price) {
      //       itemPrice = parseFloat(item.price);
      //     } else if (item.currentSize && item.currentSize.price) {
      //       itemPrice = parseFloat(item.currentSize.price);
      //     } else if (item.templateForm && item.templateForm.price) {
      //       itemPrice = parseFloat(item.templateForm.price);
      //     } else {
      //       itemPrice = 0; 
      //     }
      //     let price = itemPrice; 
      //     if (typeof item.productType === 'string' && item.productType.includes('Card') && item.cardObject && item.filteredObject?.options.length > 0) {
      //       const priceOption = item.filteredObject.options.find(
      //         option => item.quantity >= option.from && item.quantity <= option.to
      //       );
      //       if (priceOption) {
      //         price = parseFloat(priceOption.price);
      //       }
      //     }
  
      //     return {
      //       num: index + 1,
      //       key: index,
      //       image: item.image ? item.image : item.printImage,
      //       original_image: item.original_image,
      //       processedData: item.processedData ? item.processedData : '',
      //       selectedBg: item.selectedBg,
      //       quantity: item.quantity,
      //       productType: item.productType,
      //       size: `${item.currentSize?.width || ''} x ${item.currentSize?.height || ''}`,
      //       currentSize: {
      //         width: `${item.currentSize?.width || ''}`,
      //         height: `${item.currentSize?.height || ''}`,
      //       },
      //       price: price.toFixed(2),
      //        totalPrice: (price * item.quantity).toFixed(2),
      //       textValues: item.textValues ? item.textValues : '',
      //       retouch: item.retouch ? item.retouch : '',
      //       switcher: item.switcher,
      //        selectCardType: item.templateForm ? item.templateForm.product_type : '',
      //       verticalHorizontal: item.templateForm ? item.templateForm.template_pos : '',
      //       cardObject: item.cardObject,
      //       filteredObject: item.filteredObject,
      //     };
  
     
    
      // })
      state.cartData = newData;
      state.totalPrice =newData.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0).toFixed(2);
      state.quantity =  newData.reduce((total, item) => total + Number(item.quantity), 0);
      state.cartData = newData
    },
    calculateTotalsFolder:(state,action)=>{
      if(action.payload && Array.isArray(action.payload.images)){
   
        const transformedData = action.payload.images.map((image, index) => ({
          ...image,
          num: index + 1,
          key: index,
          id: image.ID,
          original_image:IMG_SERVER + image.IMAGE_LINK_PATH + H_THUMBNAILS + image.IMAGE_NAME,
          image: IMG_SERVER + image.IMAGE_LINK_PATH + H_THUMBNAILS + image.IMAGE_NAME,
          quantity: image.quantity || 1, 
          productType: action.payload.product_type,
          folder_name: action.payload.folder_name,
          selectcard_type: action.payload.selectcard_type,
          selected_size: action.payload.selected_size,
          size_id: action.payload.size_id,
          price: '0.00', 
          totalPrice: '0.00', 
      }));

      state.folderData = transformedData
      }
           
          
    },
    setCartCurrentPrice:(state,action)=>{
      state.cartCurrentPrice = action.payload
    },
    updateShopingCartData:(state,action)=>{
      const { editCard, updatedCart } = action.payload;

    
       
     state.cartData = state.cartData.map((item) =>
      item.key === editCard ? { ...item, cardObject: updatedCart.cardObject } : item
    );
    }
  }
});


export const {
  setCart,
  addToCart,
  clearAll,
  updateTotalQuantity,
  setTotalQuantity,
  updateTextValues,
  setProcessedData,
  toggleSwitch,
  setTotalPrice,
  calculateTotals,
  setCurrentCart,
  updateCart,
  calculateTotalsFolder,
  updateFolderCart,
  setCartCurrentPrice,
  updateShopingCartData,
  setTotalPriceQuantity,
  checkIsShopingCardLastElement,deleteItemShoppingCard
} = cartSlice.actions;

export default cartSlice;
