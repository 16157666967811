import React, {useContext, useEffect, useState} from 'react';
import { RollbackOutlined ,ShoppingCartOutlined} from '@ant-design/icons';
import { CartContext } from '../../../context/CartContext';
import {Col, Modal, Row} from 'antd';
import './Header.css';
import { useNavigate } from 'react-router';
import ShoppingCart from '../../Cart/ShoppingCart';
import LogoutButton from "../LogoutButton";
import HelperShow from "../../Utils/HelperShow";
import styles from "../Gallery/Gallery.module.css";
import { useCartData, useTotalQuantity } from '../../Cart/store/selector';
import { useDispatch } from 'react-redux';
import { GetCartImages } from '../../Cart/store/thunk';
import { calculateTotals, updateTotalQuantity } from '../../Cart/store/cartSlice';

const Header = ({studioInfo,helperShow,setHelperShow}) => {
  const { cart} = useContext(CartContext);
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const studio_mode = localStorage.getItem('studio_mode') || '';

  const totalQuantity = useTotalQuantity()
  const dispatch = useDispatch()
  const data = useCartData()

  useEffect(()=>{
    dispatch(GetCartImages()).unwrap().then((res)=>{
      dispatch(calculateTotals())
      dispatch(updateTotalQuantity())
  })
  },[])
  

 

  const PREFIX = "https://studioseye.com/pricing/upload/";
  const openGalleryPage = () => {
    navigate('/client/gallery_list');
    // window.location.reload();
  };

  const handleCartClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <div className='print_header'>
      <Row justify='space-between' style={{ alignItems: 'center' }}  >
        <Col>
          <span onClick={openGalleryPage} className='headerBeck'>
          <RollbackOutlined /> <span className='headerBlock'>Back to Gallery </span>
        </span>
        </Col>
        <Col>{studio_mode === 'true' ? <span className={styles.galleryBtnClientMode}>Studio mode</span> : <span  className={styles.galleryBtnClientMode}>Client mode</span>}</Col>
        <Col>
          <div className='logo' style={{ background: 'white' }}>
            {studioInfo && (
              <img src={PREFIX + studioInfo.studio_logo} alt="Studio Logo"/>
            )}
          </div>
        </Col>
        <Col  className='headerBlock'>
          <HelperShow helperShow={helperShow} setHelperShow={setHelperShow}/>
        </Col>
        <Col>
            <div onClick={handleCartClick}>
              <ShoppingCartOutlined className='cartSvg'/>
              <span className='totalQuantity'> {totalQuantity}</span>
            </div>
        </Col>
        <Col>
          <LogoutButton />
        </Col>
      </Row>
   <Modal
        title='Shopping Cart'
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        className='modalCart'
        style={{top: '0', right: '0',  marginRight: '0px'}}
      >
        <ShoppingCart cart={cart} totalQuantity={totalQuantity}  />
      </Modal>
    </div>
  );
};

export default Header;
