import React, { useEffect, useState } from 'react';
import { Result, Typography } from 'antd';
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import GeneratePDFButton from "./GeneratePDF";
import Order from "./Order";
import { useDispatch } from 'react-redux';
import { setOrderInfo } from './MainCheckout/store/mainCheckoutSlice';
import { GetOrderID } from './MainCheckout/store/thunk';
import { useOrderID } from './MainCheckout/store/selector';

const { Title, Paragraph } = Typography;

const Done = React.forwardRef((props, ref) => {

    const {shoppingCartInfo} = props

    const dispatch = useDispatch()
    const orderID = useOrderID()

  

    const [getOrderID, idLoading, idError] = useFetching(
        async (data) => {
            const {data: res} = await clientAPI.getOrderID(data);

            if (res && res.order_id) { 
                props.setOrderNumber(res.order_id); 
                props.checkOutOrder(props, res.order_id); 
              console.log(props, res.order_id)
            } else {
                console.log('Cannot add Image or get Order ID');
            }
        }
    );



    useEffect(() => {

         dispatch(GetOrderID({shoppingCartInfo}))
        //  getOrderID(props.shoppingCartInfo)

       

      const objOrder =[{
          shoppingCartInfo: props.shoppingCartInfo,
          checkout:props.checkout,
          shippingAddress:props.shippingAddress,
          cartData:props.cartData,
          orderDetails:props.orderDetails,
          order_id:props.orderNumber,
         balance: props.balance,
         totalPrice:props.totalPrice
      }]

      dispatch(setOrderInfo(objOrder))
 
    }, []);
   

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <Title level={3} style={{ color: '#223d8f' }}>
                Thank you for your order!
            </Title>
            {orderID? (
                <>
                <Paragraph>
                    <Result
                        status="success"
                        title="Your order number is:"
                        subTitle={orderID}
                    />
                </Paragraph>
                  {/*<Order props={props} />*/}
                    <GeneratePDFButton props={props} orderNumber = {orderID}/>
                </>
            ) : (
                <Paragraph>Loading order details...</Paragraph>
            )}
        </div>
    );
});

export default Done;
