import { createSlice } from "@reduxjs/toolkit";

const mainCheckoutSlice = createSlice({
    name:"mainCheckout",
    initialState:{
        creditStudio:{
           
        },
        orderDetails:{

        },
        orderInfo:[],
        orderID:"",
    },
    reducers:{
        setCreditStudio:(state,action)=>{
            state.creditStudio = action.payload.credits
        },
        setOrderDetails:(state,action)=>{
            state.orderDetails = action.payload
        },
        setOrderInfo:(state,action) =>{
        
            state.orderInfo = action.payload
        },
        setOrderID:(state,action) =>{
            state.orderID = action.payload.order_id
        }
    },
})

export const {setCreditStudio,setOrderDetails,setOrderInfo,setOrderID} = mainCheckoutSlice.actions

export default mainCheckoutSlice