import React, { useEffect, useState } from 'react';

import { Table, Button, Row, Col, notification, Space, message } from 'antd';
import { CheckCircleTwoTone, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './ShoppingCart.css';
import EditorCart from './EditorCart';
import Quantity from '../Utils/Quantity';
import MainCheckout from "./Checkout/MainCheckout/container/MainCheckout";
import CardPreview from "./CardPreview";
import { useFetching } from "../../hoc/fetchingHook";
import clientAPI from "../../api/api";
import ImageRenderer from "../Utils/ImageRenderer";
import CardEdit from "./CardEdit";
import { useCardId, useCartData, useQuantity, useTotalPrice, useTotalPriceQuantity } from './store/selector';
import { useDispatch } from 'react-redux';
import { calculateTotals, checkIsShopingCardLastElement, clearAll, setTotalPriceQuantity, updateCart } from './store/cartSlice';
import { DeleteAllImageCart, DeleteImageCart, EditImageCart, GetCartImages } from './store/thunk';


const ShoppingCart = () => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const [shoppingCartInfo, setShoppingCartInfo] = useState({
    user_id: localStorage.getItem('user_id'),
    user_type: localStorage.getItem('user_type'),
    current_user_id: localStorage.getItem('current_user_id'),
    event_id: localStorage.getItem('event_id'),
    client_id: localStorage.getItem('client_id'),
    studio_id: localStorage.getItem('studio_id'),
    event_name: localStorage.getItem('eventName'),
    owner_id: localStorage.getItem('studio_mode') === 'true' ? localStorage.getItem('studio_id') : localStorage.getItem('client_id'),
    studio_mode: localStorage.getItem('studio_mode') || '',
  });




  const dispatch = useDispatch()
  const totalPrice = useTotalPrice()
  const totalQuantity = useQuantity()
 


  const data = useCartData()

  

  const card_id = useCardId()



  const totalPriceQuantity = useTotalPriceQuantity()


  



const id = useCardId()



  const setQuantity = (key, newQuantity) => {
    const updatedItem = { ...data[key], quantity: newQuantity,totalPrice:(newQuantity *data[key].price).toFixed(2)  };
    console.log(updatedItem)
   

      dispatch(EditImageCart({id,key,updatedItem})).unwrap().then((res)=>{
       dispatch(setTotalPriceQuantity())
      }).catch((err)=>{

      })
     dispatch(updateCart({ index: key, updatedData: updatedItem }));
  };



  useEffect(()=>{
    dispatch(setTotalPriceQuantity())
  },[])







  const [storeShoppingCart, cartLoading, cartError] = useFetching(async (shoppingCartData, shoppingCardInfo) => {
    try {
      if (shoppingCartData) {
        const { data: res } = await clientAPI.storeShoppingCart(shoppingCartData, shoppingCardInfo);

      }
    } catch (error) {
      console.error(error);
    }
  });





  const handleDeleteCardImage = (record) => {

   
    dispatch(DeleteImageCart({ key: record.key, card_id })).unwrap().then((res) => {



      dispatch(checkIsShopingCardLastElement())

    }).catch((err) => {
      message.error(err.error)
    })
  };

  const handleDeleteAllCardImage = () => {

    dispatch(DeleteAllImageCart({ cart_id: card_id })).unwrap().then((res) => {
      if (res) {
        dispatch(clearAll())
      }
    }).catch((err) => {
      console.log(err)
    })
  }





  const columns = [
    {
      title: '',
      dataIndex: 'num',
      width: 2,
    },
    {
      title: 'Image',
      dataIndex: 'original_image_back',
      render: (_, record) => {

        if (record?.productType?.includes('Card') && record?.cardObject) {

          return <CardPreview cardObject={record.cardObject} />;
        }

        return <ImageRenderer image={record.original_image_back} record={record} />;
      },
      width: 400,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, record) => (
        <div>
          <Quantity
            className='cartPlus'
            setQuantity={(newQuantity) => setQuantity(record.key, newQuantity)}
            quantity={record.quantity}
          />
        </div>
      ),
      width: 100,
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      render: (_, record) => (
        <Row>
          {record.productType ? (<Col span={24}>{`${record.productType} - ${record.size}`} </Col>) : ''}

          {record.retouch && record.retouch.length > 0 ? (<Col span={24}><CheckCircleTwoTone twoToneColor="#52c41a" />  Retouch </Col>) : ''}
          {record.textValues ? (<Col span={24}>{`${record.textValues}`} </Col>) : null}
          {record.selectCardType ? (<Col span={24}>{`${record.selectCardType}`} </Col>) : ''}
          {record.verticalHorizontal ? (<Col span={24}>{`${record.verticalHorizontal}`} </Col>) : ''}
        </Row>
      ),
      width: 400,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 70,
      render: (price, record) => {
        return (
          <span>${record.price}</span>
        )
      }
    },

    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      width: 100,
      render: (totalPrice, record) => {
        if (record.quantity && record.price) {
        
          return (
            <span>${(record.quantity * record.price).toFixed(2)}</span>
          )
        }


      }
    },
    {
      title: 'Actions',
      render: (_, record) => {

     
        return (
          <div>
            {record.productType === 'Card' && (
              <>
                <Button onClick={() => setEditCard(record.key)} className='cartEdit mb5'>
                  <EditOutlined /> Edit Card
                </Button>
              </>
            )}

            {record.productType === 'Album' && (
              <>
                {/*<Button onClick={() => editAlbum(record.key)} className='cartEdit mb5'>*/}
                {/*  <EditOutlined /> Edit Album*/}
                {/*</Button>*/}
                {/* Other specific actions for Albums */}
              </>
            )}

            {record.productType === 'Print' && (
              <>
                <Button onClick={() => setSelectedItemIndex(record.key)} className='cartEdit mb5'>
                  <EditOutlined /> Edit
                </Button>
              </>
            )}

            {record.productType === 'Unit' && (
              <>
                {/*<Button onClick={() => editUnit(record.key)} className='cartEdit mb5'>*/}
                {/*  <EditOutlined /> Edit Unit*/}
                {/*</Button>*/}
                {/* Other specific actions for Units */}
              </>
            )}
            
            <Button

              onClick={() => handleDeleteCardImage(record)}
              className='cartDelete' danger>
              <DeleteOutlined /> Remove
            </Button>
          </div>
        )

      },


      width: 40,
    },
  ];

 
  useEffect(() => {
    dispatch(GetCartImages()).unwrap().then((res)=>{
      dispatch(calculateTotals())
    }).catch((err)=>{
      console.log(err)
    })
   



  }, []);




  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'Successfully',
      description: 'Data added successfully.',
    });
  };
  const saveOrder = () => {
    try {
      storeShoppingCart(data, shoppingCartInfo);
      openNotificationWithIcon('success');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };





  return (

    <>
      <Table className='scrollableDiv'
        dataSource={data}
        columns={columns}
        scroll={{ x: 100 }}
        pagination={false}
        style={{ height: '80%' }}
      />
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <span style={{ marginRight: '20px' }}>
          Total Items: <strong>{totalQuantity}</strong>
        </span>
        <span>
          Total Price: <strong>${totalPriceQuantity}</strong>
        </span>
      </div>
      <Row justify='space-between' style={{ marginTop: '20px' }}>
        <Col>         <span>
          {

            data.length ?
              (
                <>
                  <MainCheckout
                    cartData={data}
                    shoppingCartInfo={shoppingCartInfo}
                    totalPrice={totalPrice}


                  />

                  <Button className="main-checkout-button" onClick={saveOrder}>
                    Save and order later
                  </Button>
                </>
              )
              : ""
          }



        </span></Col>

        <Col>
          <Button
            className="main-image-button"

            onClick={handleDeleteAllCardImage}

          >
            Clear Cart
          </Button>
        </Col>
      </Row>
      {selectedItemIndex !== null && (
        <EditorCart
          selectedItemIndex={selectedItemIndex}
          setSelectedItemIndex={setSelectedItemIndex}
          isEditorCartOpen={true}
          setEditorCartOpen={setSelectedItemIndex}
          data={data}
          updateCart={updateCart}
          calculateTotals={calculateTotals}
        />
      )}
      {editCard !== null && (
        <CardEdit
          editCard={editCard}
          setEditCard={setEditCard}
          isEditorCartOpen={true}
          setEditorCartOpen={editCard}
        />
      )}
    </>
  );
};

export default ShoppingCart;
