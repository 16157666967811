

import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { Button, Space, Tooltip, Modal,Input, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import styles from '../Gallery.module.css';
import { useDownloadImages } from '../GalleryHeader/store/selector';

import request from '../../../../service';



const user_id = localStorage.getItem("user_id");
const studio_id = localStorage.getItem("studio_id");
const client_id = localStorage.getItem("client_id");
const event_id = localStorage.getItem("event_id");
const current_user_id = localStorage.getItem("current_user_id");
const user_type = localStorage.getItem('user_type')
const event_name = localStorage.getItem('eventName')

const AllImageDownloader = ({ selectedImages, selectionState, galleryInfo }) => {
  const IMG_SERVER = 'https://studioseye.com';
  const H_THUMBNAILS = '/H_THUMBNAILS/';
  const THUMBNAILS = '/THUMBNAILS/';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownObj, setDropdownObj] = useState({});
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("")
  
const token = localStorage.getItem("token");

  const data = useDownloadImages()






  const downloadSelectedImagesH = async () => {
    const obj = {
      ...selectionState,
      ...galleryInfo,
    };
    setDropdownObj(obj);


    setDropdownOpen(false);
  };






  const downloadSelectedImagesM = () => {
    setOpen(true)

  };


  const downloadFunction = async () => {
    try {
      const response = await request.post(
        "download_images_by_selection",
        {
          download: data.download,
          include: data.include,
          exclude: data.exclude,
          client_id,
          event_id,
          studio_id,
          email,
          ph_id: null,
          user_id
        },

        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

    
  
      if (response.data && response.data.url) {
        setOpen(false)
        const downloadLink = document.createElement("a");
        downloadLink.href = response.data.url;
        downloadLink.download = "selected_images.zip";
        downloadLink.click();
      } else {
        message.error("No download URL provided in response.")
        
      }
    } catch (error) {

      message.error(error.response.data.message || "Something wrong")
 
    }
  };
  



  // const downloadSelectedImagesM = async () => {



  //   const zip = new JSZip();

  //   await Promise.all(
  //     selectedImages.map(async (img) => {
  //       if (img) {
  //         const downloadUrl = IMG_SERVER + img.IMAGE_LINK_PATH + THUMBNAILS + img.IMAGE_NAME;
  //         const response = await fetch(downloadUrl);
  //         const blob = await response.blob();
  //         zip.file(`${img.ID}_${img.IMAGE_NAME}`, blob);
  //       }
  //     })
  //   );

  //   const zipBlob = await zip.generateAsync({ type: 'blob' });
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = URL.createObjectURL(zipBlob);
  //   downloadLink.target = '_blank';
  //   downloadLink.download = 'images.zip';
  //   downloadLink.click();
  //   setDropdownOpen(false);
  // };

  useEffect(() => {
    if (dropdownOpen) {
      const timer = setTimeout(() => {
        setDropdownOpen(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [dropdownOpen]);



  return (
    <>
      <Tooltip title="Download All Selected Images">
        <Button className={styles.buttonIcon} onClick={() => setDropdownOpen(!dropdownOpen)}>
          <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download</span>
        </Button>
      </Tooltip>
      {dropdownOpen && (
        <div className="DropdownOpenBlock DropdownImages">
          <Space direction="vertical">
            <Button onClick={downloadSelectedImagesH} className={styles.buttonIcon}>
              <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download All Selected</span>
            </Button>
            <Button onClick={downloadSelectedImagesM} className={styles.buttonIcon}>
              <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download All</span>
            </Button>
          </Space>
        </div>
      )}

      <Modal



        open={open}
        onCancel={() => setOpen(false)}
        onOk={downloadFunction}
      >
        <h4> Enter Your email addresses</h4>
        <Input
    placeholder="Enter your email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
  />
      </Modal>

    </>
  );
};

export default AllImageDownloader;
