import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Card, Col, Row} from "antd";
import Meta from "antd/es/card/Meta";
import { EditOutlined, MailOutlined,DeleteOutlined } from '@ant-design/icons';
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import ShareButton from "./UtilsGallery/ShareButton";


const GalleryList = ({activeStudio}) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const client_id = localStorage.getItem('client_id');
    const user_type = localStorage.getItem('user_type');
    const token = localStorage.getItem('token');
    const [getGalleryList, isGallerLoading, galleryError] = useFetching(async (client_id, token) => {
        const res = await clientAPI.getEventsByClientId(client_id, token);
        if (res) {
            setData(res.data);
        }
    });

    const goToGallery = (event_id,eventName) => {
        localStorage.setItem('event_id',event_id);
        localStorage.setItem('eventName', eventName);
        navigate('/gallery_page');
        window.location.reload();
    };

    useEffect(() => {
        if(client_id && token){
            getGalleryList(client_id,token);
        }
    }, []);

    useEffect(() => {
    }, [data]);

    if(!isGallerLoading && data) {
        return (
            <>
                <Row gutter={16}>
                    {data.map(c => (
                        <Col className="mrBot35" lg={6} md={8} span={24} key={c.id}>
                            <Card
                                hoverable
                                style={{ maxWidth: 300 }}
                                cover={
                                    <div className="card-cover">
                                        <img
                                            onClick={() => goToGallery(c.id, c.eventName)}
                                            alt="IMAGE NAME HERE"
                                            src={c.imgPath}
                                            className="card-cover-image"
                                        />
                                    </div>
                                }
                                actions={
                                    user_type === "CLIENT" || activeStudio === false
                                        ? [
                                            <div className='client-icon'>
                                                <ShareButton initialImageUrl={window.location.href} key="share_gallery" />
                                                <p>Share</p>
                                            </div>,
                                            <div className='client-icon'>
                                                <MailOutlined key="send_email" />
                                                <p>Mail</p>
                                            </div>,
                                        ]
                                        : [
                                            <div className='client-icon'>
                                                <ShareButton initialImageUrl={window.location.href} key="share_gallery" />
                                                <p>Share</p>
                                            </div>,
                                            <div className="client-icon">
                                                <EditOutlined key="settings" />
                                                <p>Edit</p>
                                            </div>,
                                            <div className='client-icon'>
                                                <MailOutlined key="send_email" />
                                                <p>Mail</p>
                                            </div>,
                                            <div className='client-icon'>
                                                <DeleteOutlined />
                                                <p>Delete</p>
                                            </div>,
                                        ]
                                }
                            >
                                <Meta title={c.eventType} description="Gallery Type" />
                                <Meta title={c.eventDate} description="Gallery Date" />
                                <Meta title={c.eventName} description="Gallery Name" />
                                <Meta title={c.totalPhotographers} description="Photographers" />
                                <Meta title={c.eventImages} description="Total Images" />

                                {/* Отображение фотографов и количества их изображений */}
                                <div style={{ marginTop: '10px' }}>
                                    <Meta title="Photographers" style={{ marginBottom: '10px' }}/>
                                    {c.photographers.map(photographer => (
                                        <p key={photographer.photographerID}>
                                            ID: {photographer.photographerID},
                                            Images: {photographer.uploadedImages},
                                            Original: {photographer.javaOriginalTotal},
                                            Modified: {photographer.javaModifiedTotal},
                                            Other: {photographer.javaOtherTotal},
                                        </p>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>
        );
    }else{
        return <>
            <div></div>
        </>
    }
};

export default GalleryList;