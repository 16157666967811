import React, {useState, useContext, useEffect} from 'react';
import {useDrop} from 'react-dnd';
import CardsEditor from './CardsEditor'
import {Drawer, Modal} from 'antd';
import DropZoneContext from '../../../context/DropZoneContext';
import {processImage, createDefaultImageReader, createDefaultImageWriter,} from '@pqina/pintura';
import '@pqina/pintura/pintura.css';
import CardText from "../CardDesign/CardText";

const TextAreaDiv = ({
                         index,
                         item,
                         currentSide,
                         cardObject,
                         setCardObject,

                     }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [textStyle, setTextStyle] = useState(cardObject[currentSide]?.text[0]?.style ? cardObject[currentSide]?.text[0]?.style: '');
    const [editorHtml, setEditorHtml] = useState(cardObject[currentSide]?.text[0]?.html ? cardObject[currentSide]?.text[0]?.html: '' );

    const onClose = () => {
        setCardObject(prevCardObject => {

            const newCardObject = JSON.parse(JSON.stringify(prevCardObject));
            if (!newCardObject[currentSide].text) {
                newCardObject[currentSide].text = [];
            }
            newCardObject[currentSide].text[index] = { html: editorHtml,style: textStyle};
            return newCardObject;
        });
        setIsModalOpen(false);
    };
    const changeTextModel = () => {
        setIsModalOpen(true);
    };

    const divStyleText = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        whiteSpace: 'wrap',
        overflow:'hidden',
        left: cardObject ? cardObject[currentSide]?.template[2][0]?.L + cardObject[currentSide]?.template[2][0]?.LEFT : '',
        top: cardObject ? cardObject[currentSide]?.template[2][0]?.T + cardObject[currentSide]?.template[2][0]?.TOP : '',
        width: cardObject ? cardObject[currentSide]?.template[2][0]?.W : '',
        height: cardObject ? cardObject[currentSide]?.template[2][0]?.H : '',
        ...textStyle,
    };

    return (<>
              <div
                style={{
                    ...divStyleText,
                    ...textStyle
                }}
                onClick={() => changeTextModel()}
                dangerouslySetInnerHTML={{
                    __html: editorHtml.replace(/\n/g, '<br>'),
                }}
              >
             </div>

                <Drawer
                  placement="right"
                  open={isModalOpen}
                  width={400}
                  onClose={onClose}
                >
                    <CardText
                      editorHtml={editorHtml}
                      setEditorHtml={setEditorHtml}
                      textStyle={textStyle}
                      setTextStyle={setTextStyle}
                      onUpdateText={(value) => setEditorHtml(value)}
                      onUpdateStyle={(style) => setTextStyle(style)}
                    />
            </Drawer>
        </>
    );
}

const DroppableDiv = ({item,index, currentSide,cardObject,setCardObject, }) => {
    const {imageDropZones, setImageDropZones, isDraggingElem, setIsDraggingElem} = useContext(DropZoneContext);
    const divName = `div${index}`;
    
    const [currentImage, setCurrentImage] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [backgroundImage,setBackgroundImageUrl] = useState()
    const openPinturaEditor = (divName, currentImage) => {

        setIsModalOpen(true);
    };
    // DRAG AND DROP ADD OBJECT
    useEffect(() => {
        if (currentImage && Object.keys(currentImage)?.length !== 0) {
            setCardObject(prevCardObject => {
                // Create a copy of the current images array
                const imagesCopy = [...prevCardObject[currentSide]?.images];

                // Update the specific index with currentImage
                // If index is greater than the length of the array, it will simply push the currentImage at the end.
                imagesCopy[index] = currentImage;
                
                // Return the new state with the updated images array
                return {
                    ...prevCardObject,
                    [currentSide]: {
                        ...prevCardObject[currentSide],
                        images: imagesCopy
                    }
                };
            });
        }
    }, [currentImage]);

    useEffect(() => {
        // Check if the specific image at the index is not empty
        if (cardObject[currentSide]?.images[index] && Object.keys(cardObject[currentSide]?.images[index]).length !== 0) {
            // If it's not empty, set currentImage to that image
            setCurrentImage(cardObject[currentSide]?.images[index]);
        }
    }, [index]);

    useEffect(() => {
        setCurrentImage(cardObject[currentSide]?.images[index]);
    }, [currentSide]);


    const closePinturaEditor = () => {
        setIsModalOpen(false);
    };


    const [{isOver, canDrop}, drop] = useDrop(() => ({
        accept: 'image',
        drop: (item) => {
            setIsDraggingElem(false);
            setCurrentImage(item)
            return {name: divName};
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));
    useEffect(() => {
        const processImageDataIfAvailable = async () => {
            if (currentImage?.crop_data) {
                try {
                    const {crop_data} = currentImage;
                    const res = await processImage(currentImage?.img_path, {
                        imageReader: createDefaultImageReader(),
                        imageWriter: createDefaultImageWriter(),
                        imageCrop: crop_data,
                        imageRotation: 0
                    });
                    setBackgroundImageUrl(URL.createObjectURL(res.dest));
                } catch (error) {
                    console.error("Error while processing image:", error);
                    throw error;
                }
            } else {
                setBackgroundImageUrl(currentImage?.img_path);
            }
        };

        processImageDataIfAvailable();
    }, [currentImage]);
    const divStyle = {
        position: 'absolute',
        left: item.L,
        top: item.T,
        width: item.W,
        height: item.H,
        backgroundImage: currentImage ? `url(${backgroundImage})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: isDraggingElem ? '5px solid #204998' : 'none',
        transform: item.R === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
    };
    return (<>
            <div ref={drop} style={divStyle} onClick={() => openPinturaEditor(divName, currentImage)}/>
            {isModalOpen &&  currentImage &&(
                <Modal open={isModalOpen} footer={null} onCancel={closePinturaEditor}>
                    <CardsEditor
                        divName={divName}
                        setIsModalOpen={setIsModalOpen}
                        droppedImage={currentImage}
                        aspectRatio={item.W / item.H}
                        currentImage={currentImage}
                        imageDropZones={imageDropZones}
                        setCurrentImage={setCurrentImage}
                        setImageDropZones={setImageDropZones}
                    />
                </Modal>
            )}
        </>
    );
};


const CardMainCenter = ({currentSide, cardObject, setCardObject, jsonData}) => {
    
    const mainCardStyle = {
        position: 'relative',
        left: cardObject ? cardObject[currentSide]?.template[0]?.L : jsonData[0]?.L,
        top: cardObject ? cardObject[currentSide]?.template[0]?.T : jsonData[0]?.T,
        width: cardObject ? cardObject[currentSide]?.template[0]?.W : jsonData[0]?.W,
        height: cardObject ? cardObject[currentSide]?.template[0]?.H : jsonData[0]?.H,
        backgroundImage: cardObject ? `url(${cardObject[currentSide]?.template[0]?.P})` : `url(${jsonData[0]?.P})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: '16px 20px 20px 0px #626262',
    };

    return (
        <div style={mainCardStyle}>
            {cardObject ? cardObject[currentSide]?.template[1]?.map((item, index) => (
                <DroppableDiv key={index} item={item} index={index} currentSide={currentSide} cardObject={cardObject}
                              setCardObject={setCardObject} jsonData={jsonData}/>
            )) : null}

            {cardObject ? cardObject[currentSide]?.template[2]?.map((item, index) => (
                <TextAreaDiv key={index} index={index} item={item}  currentSide={currentSide} cardObject={cardObject}
                              setCardObject={setCardObject} jsonData={jsonData}/>
            )) : null}
        </div>


    );
};

export default CardMainCenter;