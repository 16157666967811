import {combineReducers} from "redux";
import clientReducer from "./client-list-reducer";
import studioReducer from "./studio-list-reducer";
import loginReducer from "./login-reducer";
import DashboardReducer from "./dashboard-reducer";
import packageReducer from "./package-edit-reducer";
import galleryHeaderSlice from "../components/Clients/Gallery/GalleryHeader/store/galleryHeaderSlice";
import mainCheckoutSlice from "../components/Cart/Checkout/MainCheckout/store/mainCheckoutSlice";
import cartSlice from "../components/Cart/store/cartSlice";

let rootReducers = combineReducers({
    clientPage:clientReducer,
    studioPage:studioReducer,
    loginPage: loginReducer,
    dashboard: DashboardReducer,
    packageReducer: packageReducer,
    [galleryHeaderSlice.name]:galleryHeaderSlice.reducer,
    [mainCheckoutSlice.name]:mainCheckoutSlice.reducer,
    [cartSlice.name]:cartSlice.reducer
})

export default rootReducers;