import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../context/CartContext';
import { Table, Button, Row, Col } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './ShoppingCart.css';
import EditorCart from './EditorCart';
import Quantity from '../Utils/Quantity';
import {createDefaultImageReader, createDefaultImageWriter, processImage} from "@pqina/pintura";
// import MainCheckout from "./Checkout/MainCheckout";
import Lightbox from "react-18-image-lightbox";
import {useFetching} from "../../hoc/fetchingHook";
import clientAPI from "../../api/api";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { calculateTotals, calculateTotalsFolder, setCart, updateCart, updateFolderCart } from './store/cartSlice';
import { useCartData, useFolderData } from './store/selector';
import ImageRenderer from '../Utils/ImageRenderer';


const FolderOrder = ({folderData}) => {
    const IMG_SERVER = 'https://studioseye.com';
    const H_THUMBNAILS = '/H_THUMBNAILS/';
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    
    const navigate = useNavigate();
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [isLightboxLoading, setIsLightboxLoading] = useState(false);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
    const [prevSrc, setPrevSrc] = useState(undefined);

    const dispatch = useDispatch()
    const data = useFolderData()

   
  

    const imageSources = (folderData && Array.isArray(folderData.images))
        ? folderData.images.map((img) => IMG_SERVER + img.IMAGE_LINK_PATH + H_THUMBNAILS + img.IMAGE_NAME)
        : '';


    const [updateFolderData, isFolderDataLoading, isFolderDataError] = useFetching(
        async (folderNewData) => {
       
            // const {data: res} = await clientAPI.updateFolderData(folderNewData);
            // if (res) {
            //
            //     console.log(folderNewData)
            //
            // } else {
            //     console.log('Cannot add Image')
            // }
        }
    );


    const openLightbox = (ID) => {
        let index;

        if (folderData && Array.isArray(folderData.images)) {
            index = folderData.images.findIndex((image) => image.ID === ID);
        }
        setCurrentImage(index);
        setLightboxImageIndex(index);
        setLightboxIsOpen(true);
    };

    const updateQuantity = (key, newQuantity) => {
        // const newData = data.map((item) => {
        //     if (item.key === key) {
        //         return { ...item, quantity: newQuantity };
        //     }
        //     return item;
        // });

        // setData(newData); // Update the state with the new data array
    };

  
    const addFolderToCart = () => {
       
       
        
      
         updateFolderData(data);
    };

    const goToPage = () => {
       if(folderData.folder_name ==="Card"){
           navigate('/carddesign');
           window.location.reload()
       }
       if(folderData.folder_name ==="Print"){
           navigate('/prints');
           window.location.reload()
       }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'num',
            width: 2,
        },
        {
            title: 'Image',
            render: (record) => {
            
                return(
                   <ImageRenderer image={record.image} record={record} />
                    // <img onClick={() => openLightbox(record.ID)} src={record.image} width="100px"   alt="Product Image" />
                )
               
            },
            width: 120,
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            render: (_, record) => (
                <div>
                    {record.folder_name === 'Album'
                        ? <p>1</p> // Display this for 'Album'
                        : (
                            // Use the Quantity component for other folder names
                            <Quantity
                                className='cartPlus'
                                setQuantity={(newQuantity) => updateQuantity(record.key, newQuantity)}
                                quantity={record.quantity}
                            />
                        )
                    }
                </div>
            ),
            width: 100,
        },

        {
            title: 'Product Type',
            dataIndex: 'productType',
            render: (_, record) => (

                      <div>
                          <p>{record.folder_name}</p>
                          {record.selectcard_type !== '0' ? <p>{record.selectcard_type}</p> : ' '}
                          {record.selected_size !== '0' ? <p>{record.selected_size}</p> : ' '}
                      </div>

            ),
            width: 170,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (_, record) => (
                record.folder_name === 'Album' || record.folder_name === 'Other' ? null : `$${record.price}`
            ),
            width: 50,
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            render: (_, record) => (
                record.folder_name === 'Album' || record.folder_name === 'Other' ? null : `$${record.totalPrice}`
            ),
            width: 100,
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <div >
                    <Button onClick={() => setSelectedItemIndex(record.key)} className='cartEdit mb5'>
                        <EditOutlined />
                    </Button>
                </div>
            ),
            width: 40,
        },
    ];

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    useEffect(() => {
        if (folderData) {
            dispatch(calculateTotalsFolder(folderData));
        }
    }, []);

  
   







    const totalImageQuantity = data.reduce((acc, curr) => acc + curr.quantity, 0);
    const exceedsQuantity = totalImageQuantity > folderData.quantity;

    // const totalPrice = cart.reduce((total, item) => total + item.currentSize.price * item.quantity, 0).toFixed(2);

   
    return (
        <>
            <div style={{ color: exceedsQuantity ? 'red' : 'black', margin: '10px 0' }}>
                Total Quantity: {totalImageQuantity}/{folderData.quantity}
            </div>
            <Table className='scrollableDiv'
                   dataSource={data}
                   columns={columns}
                   scroll={false}
                   pagination={false}
                   style={{ height: '80%' }}
            />
            <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <span style={{ marginRight: '20px' }}>
          {/*Total Items: <strong>{totalQuantity}</strong>*/}
        </span>
                <span>
          {/*Total Price: <strong>${totalPrice}</strong>*/}
        </span>
            </div>
            <Row justify='space-between' style={{ marginTop: '20px', textAlign: 'right' }}>
                <Button  className="main-image-button" onClick={() => addFolderToCart()}>
                    Add to Cart
                </Button>
                <Button  className="main-image-button" onClick={() => goToPage()}>
                   Go to {folderData.folder_name} Page
                </Button>
            </Row>
            {selectedItemIndex !== null && (
                <EditorCart
                    selectedItemIndex={selectedItemIndex}
                    setSelectedItemIndex={setSelectedItemIndex}
                    isEditorCartOpen={true}
                    setEditorCartOpen={setSelectedItemIndex}
                    data={data}
                    // calculateTotals={calculateTotalsFolder}
                    updateCart={updateFolderCart}
                />
            )}




            {lightboxIsOpen && (
                <Lightbox
                    imageTitle={folderData.images[currentImage].IMAGE_NAME}
                    mainSrc={imageSources[lightboxImageIndex]}
                    nextSrc={
                        lightboxImageIndex === folderData.images.length - 1
                            ? undefined
                            : imageSources[(lightboxImageIndex + 1) % imageSources.length]
                    }
                    prevSrc={
                        imageSources[
                        (lightboxImageIndex + imageSources.length - 1) %
                        imageSources.length
                            ]
                    }
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => {
                        const newIndex = (lightboxImageIndex + imageSources.length - 1) % imageSources.length;
                        setCurrentImage(newIndex);
                        setLightboxImageIndex(newIndex);

                        const prevSrc =
                            newIndex === 0
                                ? undefined
                                : imageSources[(newIndex + imageSources.length - 1) % imageSources.length];

                        setPrevSrc(prevSrc);
                    }}


                    onMoveNextRequest={() => {
                        if (lightboxImageIndex < folderData.images.length - 1) {
                            const newIndex = (lightboxImageIndex + 1) % imageSources.length;
                            setCurrentImage(newIndex);
                            setLightboxImageIndex(newIndex);
                        }
                    }}

                />
            )}

        </>
    );
};

export default FolderOrder;
