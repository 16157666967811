import React, { useEffect, useState } from 'react';

import { Col, Modal, Row } from "antd";
import CardMain from "../Clients/CardDesign/CardMain";
import AddButton from "../Clients/AddButton/AddButton";
import { useCardId, useCartData } from './store/selector';
import { useDispatch } from 'react-redux';
import { updateShopingCartData } from './store/cartSlice';
import { EditImageCart, GetCartImages } from './store/thunk';

const CardEdit = ({ editCard, setEditCard, isEditorCartOpen }) => {

  const [formData, setFormData] = useState({});
  const cart = useCartData()
  const dispatch = useDispatch()

  const [cardObject, setCardObject] = useState([]);
  const card_id = useCardId()

  useEffect(() => {
    if (editCard !== null) {
      const selectedItem = cart[editCard];

      if (selectedItem) {
        setFormData({ ...selectedItem });
      }
    }
  }, [editCard, cart]);

  const handleModalClose = () => {
    setEditCard(null);
  };




  const updateCartData = () => {



    const { cardObject: _, ...formDataWithoutCardObject } = formData;
    const updateCart = {
      ...formDataWithoutCardObject, 
      cardObject,     
    };

  
    

      dispatch(EditImageCart({id:card_id,key:editCard,updatedItem:updateCart})).unwrap().then((res)=>{
           
      }).catch((err)=>{

      })



       dispatch(updateShopingCartData({ updatedCart: updateCart, editCard }))

    handleModalClose();
  };


  
  return (
    <Modal
      title="Edit Shopping Cart"
      open={editCard !== null}
      onCancel={handleModalClose}
      footer={null}
      className="modalEditCard"
    >
      <Row>
        <Col span={24}>
          <CardMain
            isEditorCartOpen={isEditorCartOpen}
            cardObjectEdit={formData.cardObject}
            setCardObject={setCardObject}
            cardObject={cardObject}

          // updateCartData={updateCartData}
          />
        </Col>
        <Col span={24}>
          <AddButton
            quantity={formData.quantity}
            updateCartData={updateCartData}
            // printImage={formData.image}
            // currentSize={formData.currentSize}
            // processedData={formData.processedDataI}
            isEditorCartOpen={isEditorCartOpen}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CardEdit;
